import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineModeEditOutline } from "react-icons/md";
import AddAdmin from "Components/admin/Adduser";
function User() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [addForm, setAddForm] = useState(false);
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [id, setid] = useState();
    const [editable, setEditable] = useState(false);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        padding: "0 0.3rem",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const formHandler = () => {
        setAddForm((prev) => !prev);
    };
    const edithandler = (e) => {
        console.log(e.currentTarget.getAttribute("id"));
        setid(e.currentTarget.getAttribute("id"));
        setEditable(true);
        setAddForm(true);
    };
    const getusers = (offset) => {
        Services.getadminUsers("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.AdminUsers);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.Offset);
                    }

                    if (Response.Offset !== -1) {
                        setoffset(Response.Offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);

        // eslint-disable-next-line
    }, []);
    const roleFormatter = (cell) => {
        if (cell === 1) {
            return <p style={{ margin: 0 }}>Super Admin</p>;
        }

        if (cell === 2) {
            return <p style={{ margin: 0 }}> Employee</p>;
        }
    };
    const statusformatter = (cell) => {
        if (cell === 0) {
            return <p style={{ margin: 0 }}>Inactive</p>;
        }
        if (cell === 1) {
            return <p style={{ margin: 0 }}>Active</p>;
        }

        if (cell === 2) {
            return <p style={{ margin: 0 }}> Approval pending</p>;
        }
    };
    const deleteHandler = (e) => {
        if (window.confirm("are you sure you want to delete ")) {
            Services.DeleteAdmin(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.Status === 1) {
                        setTimeout(window.location.reload(), 2000);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // if (res.Message === "Token expired") {
                        //     Navigate("/");
                        // }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={tableClasses["action-btns"]}>
                <button id={cellcontent.adminUserId} onClick={edithandler}>
                    <MdOutlineModeEditOutline size={22} />
                </button>
                <button id={cellcontent.adminUserId} onClick={deleteHandler}>
                    <AiOutlineDelete size={22} />
                </button>
            </div>
        );
    };
    const columns = [
        {
            dataField: "firstName",
            text: "Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "emailId",
            text: "Email",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },
        {
            dataField: "mobileNumber",
            text: "Mobile Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "role",
            text: "Role",
            sort: false,
            formatter: roleFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: false,
            formatter: statusformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "Action",
            text: "Action",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    console.log(addForm);
    return (
        <>
            {addForm && (
                <AddAdmin
                    setmodal={setAddForm}
                    editable={editable}
                    id={id}
                    setEditable={setEditable}
                    getCategories={getusers}
                />
            )}
            <Layout isactive={"admin"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["table"]}>
                        <h3 className={tableClasses["heading"]}>Admin Users</h3>
                        <button
                            onClick={formHandler}
                            style={{ margin: "1rem 0" }}>
                            Add Admin
                        </button>
                        {/* <div className={tableClasses["search"]}>
                            <div>
                                <input
                                    type={"text"}
                                    placeholder="Search by name"
                                />
                                <BiSearchAlt2
                                    className={tableClasses["search-icon"]}
                                    size={25}
                                    color={"#2e3346"}
                                />
                            </div>
                            <button>
                                <BsDownload />
                                <p>Download</p>
                            </button>
                        </div> */}
                        <Table
                            data={data}
                            columns={columns}
                            getdata={getusers}
                            getoffset={offset}
                            max={max}
                            setNext={setNext}
                            next={next}
                            prev={prev}
                            setprev={setprev}
                            prevoffset={prevoffset}
                            setPrevOffset={setPrevOffset}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
}

export default User;
