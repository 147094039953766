import React from "react";
import mainClasses from "Styles/mainCss.module.css";
import ClipLoader from "react-spinners/ClipLoader";
function CircleLoad() {
    return (
        <div className={mainClasses["circle-Loader-wrapper"]}>
            <div className={mainClasses["circle-loader"]}>
                <ClipLoader color="black" size={80} />
            </div>
        </div>
    );
}

export default CircleLoad;
