import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/Login/login";
import User from "./Components/User/user";
import KycPending from "./Components/kycPending/Pending";
import Verified from "./Components/Verified/Verified";
import Transaction from "./Components/Transaction/transaction";
import WalletSummary from "./Components/walletSummary/sumary";
import Referral from "./Components/Referral/referral";
import Settings from "./Components/settings/settings";
import AdminUsers from "./Components/admin/adminusers";
import UserDetails from "./Components/Verified/Userdetails";
import UserWallet from "./Components/walletSummary/User-wallet-details";
import BlockedUsers from "./Components/BlockedAccounts/BlockedUsers";
import PayoutContacts from "./Components/Verified/PayoutContacts";
import Dashboard from "Components/Dashboard/dashboard";
import ReferralUsers from "Components/Verified/referralUsers";
import RefferalCommision from "Components/ReferralCommisions/referral";
import PayoutContactsVerify from 'Components/payoutContacts/payout'
import ToneTransactions from 'Components/ToneTransactions/Transactions'
function App() {
    return (
        <>
            <ToastContainer />
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/dashboard/user" element={<User />} />
                <Route exact path="/dashboard/T1-transactions" element={<ToneTransactions />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route
                    exact
                    path="/dashboard/kyc-pending-users"
                    element={<KycPending />}
                />
                <Route
                    exact
                    path="/dashboard/verified-users"
                    element={<Verified />}
                />
                <Route
                    exact
                    path="/dashboard/transaction-history"
                    element={<Transaction />}
                />
                <Route
                    exact
                    path="/dashboard/wallet-summary"
                    element={<WalletSummary />}
                />
                <Route
                    exact
                    path="/dashboard/payout-contacts-verify"
                    element={<PayoutContactsVerify />}
                />
                <Route
                    exact
                    path="/dashboard/referral"
                    element={<Referral />}
                />
                <Route
                    exact
                    path="/dashboard/settings"
                    element={<Settings />}
                />
                <Route
                    exact
                    path="/dashboard/admin-users"
                    element={<AdminUsers />}
                />
                <Route
                    exact
                    path="/dashboard/verified-users/:id"
                    element={<UserDetails />}
                />
                <Route
                    exact
                    path="/dashboard/wallet-summary/:id"
                    element={<UserWallet />}
                />
                <Route
                    exact
                    path="dashboard/blockedAccounts"
                    element={<BlockedUsers />}
                />
                <Route
                    exact
                    path="payoutContacts/:id"
                    element={<PayoutContacts />}
                />
                <Route
                    exact
                    path="ReferralUsers/:userid"
                    element={<ReferralUsers />}
                />
                <Route
                    exact
                    path="referralCommission"
                    element={<RefferalCommision />}
                />
            </Routes>
        </>
    );
}

export default App;
