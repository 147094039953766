import React, { useState, useEffect } from "react";
import tableClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
import { Services } from "Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            // Services.getBannerbyId("GET", null, token, props.id)
            //     .then((res) => {
            //         console.log(res);
            //         if (res?.Status === 1) {
            //             //res.categories.imageUrl
            //             setFile(res.banners.imageUrl);
            //             reset({
            //                 bannerName: res.banners.bannerName,
            //                 priority: res.banners.priority,
            //                 status: res.banners.status,
            //                 categoryId: res.banners.categoryId,
            //             });
            //         } else if (res.Status === 0) {
            //             toast.error(`${res.Message}`, {
            //                 position: "top-center",
            //                 autoClose: 1000,
            //                 hideProgressBar: true,
            //                 closeOnClick: true,
            //                 pauseOnHover: true,
            //                 draggable: true,
            //                 progress: undefined,
            //             });
            //             if (res.Message === "Token expired") {
            //                 Navigate("/");
            //             }
            //         }
            //     })
            //     .catch((err) => {
            //         // alert(err);
            //         alert("something went wrong please try again");
            //         console.log(err);
            //     });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    const onSubmit = (data) => {
        console.log(data);

        let method = JSON.stringify({
            ...data,
        });
        if (!props.editable) {
            Services.addReferral("POST", method, token)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories(0);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateReferral("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories(0);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={tableClasses.modal} onClick={CancelHandler}>
            <div
                className={tableClasses["modal-content"]}
                onClick={openhandler}>
                <div className={tableClasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update User" : "Add Referral"}
                        </h3>
                        <p>
                            {props.editable ? "Update User" : "Add Referral"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={tableClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={tableClasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="Name"> Name</label>
                        <div className={tableClasses["select"]}>
                            <input
                                {...register("name", {
                                    required: "Name is required",
                                })}
                            />

                            {errors?.name && (
                                <p className={tableClasses["error"]}>
                                    {errors?.name?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <div className={tableClasses["select"]}>
                            <input
                                type={"number"}
                                {...register("mobileNumber", {
                                    required: "Mobile Number is required",
                                })}
                            />

                            {errors?.mobileNumber && (
                                <p className={tableClasses["error"]}>
                                    {errors?.mobileNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="referralCode">Referral Code</label>
                        <div className={tableClasses["select"]}>
                            <input
                                type={"text"}
                                {...register("referralCode", {
                                    required: "Referral Code is required",
                                })}
                            />

                            {errors?.referralCode && (
                                <p className={tableClasses["error"]}>
                                    {errors?.referralCode?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={tableClasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={tableClasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Referral"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
