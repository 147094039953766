import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import AddAdmin from "Components/BlockedAccounts/Adduser";
import Moment from "react-moment";
import CircleLoader from "../utilities/CircleLoad";
function User() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [downloadload, setdownloadload] = useState(false);
    let firstTime;
    const [addForm, setAddForm] = useState(false);
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [editable, setEditable] = useState(false);
    const [transactionType, setTransactionType] = useState(2);
    const [status, setStatus] = useState(1);
    const [circleLoad, setCircleLoad] = useState(false);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        padding: "0 0.3rem",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const formHandler = () => {
        setAddForm((prev) => !prev);
    };
    const exportHandler = () => {
        setdownloadload(true);
        Services.downloadCsvPendingT1("GET", null, token)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);

                setdownloadload(false);
                if (Response.Status === 1) {
                    window.open(Response.filename, "_blank");
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
                setdownloadload(false);
            });
    };
    const getusers = (offset, transactionsType) => {
        Services.t1Transactions("GET", null, token, offset, transactionsType)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.TOnePayoutTransactions);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.Offset);
                    }
                    if (Response.Offset !== -1) {
                        setoffset(Response.Offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0, transactionType);

        // eslint-disable-next-line
    }, []);

    const paidHandler = (message, status, userId, payoutTransactionId) => {
        if (window.confirm(`are you sure you want to ${message} `)) {
            let body = {
                userId: userId,
                status: status,
            };
            setCircleLoad(true);
            Services.UpdateTransaction(
                "PUT",
                JSON.stringify(body),
                token,
                payoutTransactionId
            )
                .then((res) => {
                    setCircleLoad(false);
                    if (res.Status === 1) {
                        setData((prev) =>
                            prev.filter(
                                (item) =>
                                    item.payoutTransactionId !=
                                    payoutTransactionId
                            )
                        );
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // if (res.Message === "Token expired") {
                        //     Navigate("/");
                        // }
                    }
                })
                .catch((err) => {
                    // alert(err);

                    setCircleLoad(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const dateFormatter = (cell) => {
        const date = new Date(cell);
        return (
            <Moment
                format="MMMM Do YYYY hh:mm a"
                subtract={{ hours: 5, minutes: 30 }}>
                {date}
            </Moment>
        );
    };
    const actionFormatter = (cell, cellContent) => {
        return (
            <div
                className={tableClasses["formatter-btns"]}
                style={{ flexWrap: "wrap" }}>
                <button
                    className="paid-button"
                    btnType={1}
                    userId={cellContent.userId}
                    onClick={() => {
                        paidHandler(
                            "Approve",
                            1,
                            cellContent.userId,
                            cellContent.payoutTransactionId
                        );
                    }}>
                    Approve
                </button>
                <button
                    className="paid-button"
                    btnType={1}
                    userId={cellContent.userId}
                    onClick={() => {
                        paidHandler(
                            "Reject",
                            3,
                            cellContent.userId,
                            cellContent.payoutTransactionId
                        );
                    }}>
                    Reject
                </button>
            </div>
        );
    };
    let columns = [
        {
            dataField: "firstName",
            text: "Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "bankAccountName",
            text: "Account Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "bankName",
            text: "bank Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "15%" };
            },
        },
        {
            dataField: "bankAccountNumber",
            text: "Account Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "payoutAmount",
            text: "Amount",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "mobileNumber",
            text: "Mobile Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "paymentGateway",
            text: "PG",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionCreatedOn",
            text: "Date",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "Action",
            text: "Action",
            sort: false,
            formatter: actionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    if (transactionType != 2) {
        columns = columns.filter((item) => item.dataField != "Action");
    }
    const navhandler = (e) => {
        let btntype = e.currentTarget.getAttribute("btntype");
        setTransactionType(Number(btntype));
        getusers(0, btntype);
        setLoading(true);
    };
    return (
        <>
            <Layout isactive={"T1"}>
                {circleLoad && <CircleLoader />}
                <div className={tableClasses["table"]}>
                    <h3 className={tableClasses["heading"]}>T1 Transactions</h3>

                    <div className={tableClasses["subnav"]}>
                        <button
                            className={
                                transactionType === 2
                                    ? tableClasses["active-nav"]
                                    : ""
                            }
                            type="button"
                            btntype={2}
                            onClick={navhandler}>
                            Pending
                        </button>
                        <button
                            className={
                                transactionType === 1
                                    ? tableClasses["active-nav"]
                                    : ""
                            }
                            type="button"
                            btntype={1}
                            onClick={navhandler}>
                            Approved
                        </button>
                        <button
                            className={
                                transactionType === 3
                                    ? tableClasses["active-nav"]
                                    : ""
                            }
                            type="button"
                            btntype={3}
                            onClick={navhandler}>
                            Rejected
                        </button>
                    </div>
                    {transactionType === 2 && (
                        <div
                            style={{ justifySelf: "end" }}
                            className={tableClasses["verified-export"]}>
                            <button
                                onClick={exportHandler}
                                style={{ margin: "0.5rem" }}>
                                {downloadload ? "loading..." : "export"}
                            </button>
                        </div>
                    )}
                    {loading ? (
                        <Loader />
                    ) : (
                        <Table
                            data={data}
                            columns={columns}
                            getdata={(offset) =>
                                getusers(offset, transactionType)
                            }
                            getoffset={offset}
                            max={max}
                            setNext={setNext}
                            next={next}
                            prev={prev}
                            setprev={setprev}
                            prevoffset={prevoffset}
                            setPrevOffset={setPrevOffset}
                        />
                    )}
                </div>
            </Layout>
        </>
    );
}

export default User;
