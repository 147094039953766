import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
function User() {
    const [loading, setLoading] = useState(true);
    const [details, setdetails] = useState({});

    const token = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const getusers = (offset) => {
        Services.getSettings("GET", null, token)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setdetails(Response.Settings);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers();

        // eslint-disable-next-line
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const formdata = JSON.stringify({
            ...details,
        });
        console.log(formdata);
        // return;

        Services.getSettings("PUT", formdata, token)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    toast.success(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };

    const SettingChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name, value);
        setdetails((prev) => {
            return {
                ...prev,
                [name]: Number(value),
            };
        });
    };

    return (
        <Layout isactive={"settings"}>
            {loading ? (
                <Loader />
            ) : (
                <div className={tableClasses["table"]}>
                    <h3 className={tableClasses["heading"]}>Settings </h3>
                    <form
                        className={tableClasses["settings"]}
                        onSubmit={onSubmit}>
                        <div className={tableClasses["settings-data"]}>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="rechargeLock">
                                    recharge Lock
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="rechargeLock"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.rechargeLock === 1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="rechargeLock"
                                            value={0}
                                            defaultChecked={
                                                details.rechargeLock === 0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="payoutLock">payout Lock</label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="payoutLock"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.payoutLock === 1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="payoutLock"
                                            value={0}
                                            defaultChecked={
                                                details.payoutLock === 0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="completeSystemLock">
                                    Complete System Lock
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="completeSystemLock"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.completeSystemLock === 1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="completeSystemLock"
                                            value={0}
                                            defaultChecked={
                                                details.completeSystemLock === 0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="isBBPSvisible">
                                    BBPS visible
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="isBBPSvisible"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.isBBPSvisible === 1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="isBBPSvisible"
                                            value={0}
                                            defaultChecked={
                                                details.isBBPSvisible === 0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="PayUPGAvailable">
                                    Payu Gateway Available
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="isPayUPGAvailable"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.isPayUPGAvailable === 1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="isPayUPGAvailable"
                                            value={0}
                                            defaultChecked={
                                                details.isPayUPGAvailable === 0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="isSettlementsEnabled">
                                    PG1 Settlements
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="isSettlementsEnabled"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.isSettlementsEnabled ===
                                                1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="isSettlementsEnabled"
                                            value={0}
                                            defaultChecked={
                                                details.isSettlementsEnabled ===
                                                0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="isSettlementsEnabled">
                                    PG2 Settlements
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="isPG2SettlementsEnabled"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.isPG2SettlementsEnabled ===
                                                1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="isPG2SettlementsEnabled"
                                            value={0}
                                            defaultChecked={
                                                details.isPG2SettlementsEnabled ===
                                                0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="isRazorPayPGAvailable">
                                    Razorpay Gateway Available
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="isRazorPayPGAvailable"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.isRazorPayPGAvailable ===
                                                1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="isRazorPayPGAvailable"
                                            value={0}
                                            defaultChecked={
                                                details.isRazorPayPGAvailable ===
                                                0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="isRazorPayPGAvailable">
                                    RazorPay PG2 Available
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="isRazorPayPG2Available"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.isRazorPayPG2Available ===
                                                1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="isRazorPayPG2Available"
                                            value={0}
                                            defaultChecked={
                                                details.isRazorPayPG2Available ===
                                                0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="isRazorPayPGAvailable">
                                    Payout Account Switch
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="accountSwitch"
                                            value={0}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.accountSwitch === 0
                                            }
                                        />
                                        <label htmlFor="yes">
                                            Bank Account{" "}
                                        </label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="accountSwitch"
                                            value={1}
                                            defaultChecked={
                                                details.accountSwitch === 1
                                            }
                                        />
                                        <label htmlFor="No">
                                            Virtual Account
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-detail"]}>
                                <label htmlFor="referralCommissionEnabled">
                                    Referral Commission
                                </label>
                                <div
                                    className={
                                        tableClasses["radio-button-wrapper"]
                                    }>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            name="referralCommissionEnabled"
                                            value={1}
                                            onChange={SettingChangeHandler}
                                            defaultChecked={
                                                details.referralCommissionEnabled ===
                                                1
                                            }
                                        />
                                        <label htmlFor="yes">enable</label>
                                    </div>
                                    <div
                                        className={
                                            tableClasses["radio-buttons"]
                                        }>
                                        <input
                                            type={"radio"}
                                            onChange={SettingChangeHandler}
                                            name="referralCommissionEnabled"
                                            value={0}
                                            defaultChecked={
                                                details.referralCommissionEnabled ===
                                                0
                                            }
                                        />
                                        <label htmlFor="No">disable</label>
                                    </div>
                                </div>
                            </div>
                            <div className={tableClasses["settting-data"]}>
                                <label htmlFor="defaultUserPercentage">
                                    Default User Percentage
                                </label>
                                <input
                                    type={"number"}
                                    step={0.01}
                                    defaultValue={
                                        details?.defaultUserPercentage
                                    }
                                    name="defaultUserPercentage"
                                    onChange={SettingChangeHandler}
                                />
                            </div>
                            <div className={tableClasses["settting-data"]}>
                                <label htmlFor="defaultUserPercentage">
                                    PG2 User Percentage
                                </label>
                                <input
                                    type={"number"}
                                    step={0.01}
                                    defaultValue={details?.PG2UserPercentage}
                                    name="PG2UserPercentage"
                                    onChange={SettingChangeHandler}
                                />
                            </div>
                            <div className={tableClasses["settting-data"]}>
                                <label htmlFor="defaultUserPercentage">
                                    Default Payout Charges
                                </label>
                                <input
                                    type={"number"}
                                    step={0.01}
                                    name="defaultPayoutCharges"
                                    defaultValue={details?.defaultPayoutCharges}
                                    onChange={SettingChangeHandler}
                                />
                            </div>
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            )}
        </Layout>
    );
}

export default User;
