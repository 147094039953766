import React, { useState } from "react";
import { useForm } from "react-hook-form";
import bankClasses from "./password.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { GrHide } from "react-icons/gr";
import { BiShowAlt } from "react-icons/bi";
import { Services } from "Services";
import { useNavigate } from "react-router";
function BankContact(props) {
    const [verification, setVerification] = useState(false);
    const Navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors },
    } = useForm();
    const formData = watch();
    const onSubmit = (data) => {
        let userData = JSON.parse(localStorage.getItem("userdetails"));
        if (data.newPassword !== data.ConfirmPassword) {
            setError("ConfirmPassword", {
                type: "custom",
                message: "New Password and confirm Password needs to be same",
            });
            return;
        }
        Services.ChangePassword(
            "POST",
            JSON.stringify({
                ...data,
            }),
            token,
            userData.adminUserId
        )
            .then((Response) => {
                setLoading(false);
                console.log(Response);
                if (Response.Status === 1) {
                    localStorage.clear();
                    sessionStorage.clear();
                    Navigate("/");
                    toast.success(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (Response.Status === 0) {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <Modal
            open={props.modal}
            center
            closeOnEsc={false}
            showCloseIcon={false}>
            <div className={bankClasses["form"]}>
                <h3>Change Password</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={bankClasses["form-control"]}>
                        <input
                            placeholder="Current Password"
                            type={"text"}
                            {...register("currentPassword", {
                                required: "This is required!",
                            })}
                        />
                        {errors.currentPassword && (
                            <p>{errors?.currentPassword.message}</p>
                        )}
                    </div>
                    <div className={bankClasses["form-control"]}>
                        <div>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="New Password"
                                {...register("newPassword", {
                                    required: "This is required!",
                                })}
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    setShowPassword((prev) => !prev)
                                }>
                                {showPassword ? <BiShowAlt /> : <GrHide />}
                            </button>
                        </div>
                        {errors.newPassword && (
                            <p>{errors?.newPassword.message}</p>
                        )}
                    </div>
                    <div className={bankClasses["form-control"]}>
                        <div>
                            <input
                                placeholder="Confirm Password"
                                type={showConfirmPassword ? "text" : "password"}
                                {...register("ConfirmPassword", {
                                    required: "This is required!",
                                })}
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    setShowConfirmPassword((prev) => !prev)
                                }>
                                {showConfirmPassword ? (
                                    <BiShowAlt />
                                ) : (
                                    <GrHide />
                                )}
                            </button>
                        </div>
                        {errors.ConfirmPassword && (
                            <p>{errors?.ConfirmPassword.message}</p>
                        )}
                    </div>

                    {/* {loading ? (
                        <ClipLoader
                            color={"black"}
                            loading={true}
                            css={override}
                            size={30}
                        />
                    ) : (
                        <div className={bankClasses["checkbox"]}>
                            <input
                                onChange={verificationchange}
                                type="checkbox"
                                id="verification"
                                name="verification"
                                value="verification"
                                checked={verification}
                            />
                            <label for="verification">
                                {" "}
                                is verification required (Rs.4 for processing
                                charges)
                            </label>
                            <br />
                        </div>
                    )} */}

                    <button type="submit">Change Password</button>
                </form>
            </div>
        </Modal>
    );
}

export default BankContact;
