import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";

function User() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [prevoffset, setPrevOffset] = useState();
    const [active, setActive] = useState("pending");
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [max, setMax] = useState();

    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const rejected = (offset) => {
        Services.rejected("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.PayoutContacts);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.Offset);
                    }
                    if (Response.Offset !== -1) {
                        setoffset(Response.Offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const getusers = (offset) => {
        Services.PayoutVerify("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.PayoutContacts);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.Offset);
                    }
                    if (Response.Offset !== -1) {
                        setoffset(Response.Offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);

        // eslint-disable-next-line
    }, []);

    const approveHandler = (e, type) => {
        let payout_contactId = e.currentTarget.getAttribute("payout_contactId");
        if (window.confirm(`are you sure you want to ${type}`)) {
            let body;
            if (type == "approve") {
                body = { IsAdminApproved: 1 };
            } else {
                body = { IsAdminApproved: 2 };
            }
            Services.PayoutApprove(
                "POST",
                JSON.stringify(body),
                token,
                payout_contactId
            )
                .then((res) => {
                    if (res.Status === 1) {
                        getusers(0);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // if (res.Message === "Token expired") {
                        //     Navigate("/");
                        // }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const StatusFormatter = (cell, cellContent) => {
        if (cellContent.IsAdminApproved == 0) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        justifyContent: "center",
                    }}>
                    <button
                        style={{
                            border: "1px solid black",
                            color: "green",
                            backgroundColor: "white",
                        }}
                        onClick={(e) => {
                            approveHandler(e, "approve");
                        }}
                        payout_contactId={cellContent.payout_contactId}>
                        Approve
                    </button>
                    <button
                        style={{
                            border: "1px solid black",
                            color: "red",
                            backgroundColor: "white",
                        }}
                        onClick={(e) => {
                            approveHandler(e, "reject");
                        }}
                        payout_contactId={cellContent.payout_contactId}>
                        Reject
                    </button>
                </div>
            );
        }
        if (cellContent.IsAdminApproved === 1) {
            /* <p
                    style={{
                        color: "green",
                        fontWeight: "bold",
                    }}>
                    Approved
                </p> */
            return (
                <button
                    style={{
                        border: "1px solid black",
                        color: "red",
                        backgroundColor: "white",
                    }}
                    onClick={(e) => {
                        approveHandler(e, "reject");
                    }}
                    payout_contactId={cellContent.payout_contactId}>
                    Reject
                </button>
            );
        }
        if (cellContent.IsAdminApproved === 2) {
            return (
                <button
                    style={{
                        border: "1px solid black",
                        color: "green",
                        backgroundColor: "white",
                    }}
                    onClick={(e) => {
                        approveHandler(e, "approve");
                    }}
                    payout_contactId={cellContent.payout_contactId}>
                    Approve
                </button>
            );
        }
    };
    const columns = [
        {
            dataField: "bankAccountName",
            text: "Payee",
            sort: false,
            formatter: (cell, cellcontent) => {
                return (
                    <p>
                        {cellcontent.bankAccountName}
                        <br />
                        {cellcontent.mobileNumber}
                    </p>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "bankAccountNumber",
            text: "Account Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "bankIFSCCode",
            text: "Bank Details",
            sort: false,
            formatter: (cell, cellContent) => {
                return (
                    <p>
                        {cellContent.bankName}
                        <br />
                        {cell}
                    </p>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },
        {
            dataField: "Payer",
            text: "Payer",
            sort: false,
            formatter: (cell, cellContent) => {
                return (
                    <p>
                        {cellContent.firstName}
                        <br />
                        {cellContent.payerMobileNo}
                    </p>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },

        {
            dataField: "PAN",
            text: "PAN Number",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionStatus",
            text: "Status",
            sort: false,
            formatter: StatusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];

    return (
        <>
            <Layout isactive={"payoutContacts"}>
                <div className={tableClasses["table"]}>
                    <h3 className={tableClasses["heading"]}>Payout Contacts</h3>

                    <div className={tableClasses["payout-nav"]}>
                        <button
                            className={
                                active == "pending"
                                    ? tableClasses["nav-active"]
                                    : ""
                            }
                            onClick={() => {
                                setLoading(true);
                                getusers(0);
                                firstTime = true;
                                setActive("pending");
                            }}>
                            Pending
                        </button>
                        <button
                            className={
                                active == "rejected"
                                    ? tableClasses["nav-active"]
                                    : ""
                            }
                            onClick={() => {
                                setLoading(true);
                                rejected(0);
                                firstTime = true;
                                setActive("rejected");
                            }}>
                            Rejected
                        </button>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Table
                            data={data}
                            columns={columns}
                            getdata={active == "pending" ? getusers : rejected}
                            getoffset={offset}
                            max={max}
                            setNext={setNext}
                            next={next}
                            prev={prev}
                            setprev={setprev}
                            prevoffset={prevoffset}
                            setPrevOffset={setPrevOffset}
                        />
                    )}
                </div>
            </Layout>
        </>
    );
}

export default User;
