import React, { useState, useEffect } from "react";
import cssClasses from "Styles/Style.module.css";
import Sidenav from "Components/sideNav/sideNav";
import PasswordChange from "Components/Dashboard/changePassword/password";
function Layout({ isactive, children }) {
    const [modal, setmodal] = useState(false);
    useEffect(() => {
        let AdminDetails = JSON.parse(localStorage.getItem("userdetails"));
        if (AdminDetails.role == 2 && AdminDetails.isPasswordChanged == 1) {
            setmodal(true);
        }
    }, []);
    return (
        <div className={cssClasses["wrapper"]}>
            <PasswordChange modal={modal} setmodal={setmodal} />
            <div className={cssClasses["left-div"]}>
                <Sidenav isActive={isactive} />
            </div>
            <div className={cssClasses["right-div"]}>{children}</div>
        </div>
    );
}

export default Layout;
