import React, { useEffect } from "react";
import "Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
export default function Table({
    data,
    columns,
    getdata,
    getoffset,
    max,
    next,
    setNext,
    prev,
    setprev,
    setPrevOffset,
    prevoffset,
    rowevents,
    rowClasses,
}) {
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    useEffect(() => {
        // eslint-disable-next-line
        if (prevoffset == 0) {
            setprev(true);
        }
        // eslint-disable-next-line
    }, [prevoffset]);
    const prevHandler = () => {
        if (prevoffset >= max) {
            getdata(prevoffset - max);
            setPrevOffset((prev) => prev - max);
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setprev(true);
            getdata(0);
        }
    };
    const nexthandler = () => {
        if (getoffset !== -1) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setNext(false);
            getdata(getoffset);
            setPrevOffset(getoffset);
            setprev(false);
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setNext(true);
        }
    };
    const emptyDataMessage = () => {
        return "No Data to Display";
    };
    return (
        <div className="category-table">
            <BootstrapTable
                noDataIndication={emptyDataMessage}
                bootstrap4
                keyField="id"
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                // pagination={pagination}
                wrapperClasses="table-responsive"
                //   rowEvents={onclick}
                rowClasses={rowClasses}
                rowEvents={rowevents}
            />
            <div className="pagination">
                <div className="buttons">
                    <button onClick={prevHandler} disabled={prev}>
                        Prev
                    </button>
                    <button onClick={nexthandler} disabled={next}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}
