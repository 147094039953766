import React from "react";
import tableClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";
import Moment from "react-moment";
// import User from "../../Assets/user.svg";
function AddCategory(props) {
    const transactionDetails = props.userData;
    console.log(transactionDetails);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={tableClasses.modal} onClick={CancelHandler}>
            <div
                className={`${tableClasses["modal-content"]} ${tableClasses["modal-transaction-details"]} `}
                onClick={openhandler}>
                <div className={tableClasses["modal-content-header"]}>
                    <div>
                        <h3>User Details</h3>
                    </div>
                    <button
                        className={tableClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <div className={tableClasses["add-items"]}>
                    {/* <img
                        src={
                            transactionDetails.profilePic !== "NA"
                                ? transactionDetails.profilePic
                                : User
                        }
                        alt="profilepic"
                    /> */}
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>User ID</h3>
                        <p>:</p>
                        <p>{transactionDetails?.userId}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Name</h3>
                        <p>:</p>
                        <p>{transactionDetails?.firstName}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Mobile Number</h3>
                        <p>:</p>
                        <p>{transactionDetails.mobileNumber}</p>
                    </div>

                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Gender</h3>
                        <p>:</p>
                        <p>{transactionDetails?.gender}</p>
                    </div>

                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Email</h3>
                        <p>:</p>
                        <p>{transactionDetails?.emailId}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Address</h3>
                        <p>:</p>
                        <p>{transactionDetails?.address}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>DOB</h3>
                        <p>:</p>
                        <p>{transactionDetails?.DOB}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>PAN</h3>
                        <p>:</p>
                        <p>{transactionDetails?.PAN}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Aadhar</h3>
                        <p>:</p>
                        <p>{transactionDetails?.aadharNumber}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Wallet Amount</h3>
                        <p>:</p>
                        <p>{transactionDetails.walletAmount}</p>
                    </div>
                    {/* <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Payment PageId</h3>
                        <p>:</p>
                        <p>{transactionDetails?.paymentPageId}</p>
                    </div> */}
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Payoutblocked</h3>
                        <p>:</p>
                        <p>
                            {" "}
                            {transactionDetails.isPayoutBlocked === 1
                                ? "YES"
                                : "NO"}
                        </p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>Referred by code</h3>
                        <p>:</p>
                        <p>{transactionDetails?.referredByCode}</p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["whitebg"]}`}>
                        <h3>Payout charge amount</h3>
                        <p>:</p>
                        <p style={{ margin: "0" }}>
                            {transactionDetails?.payoutChargeAmount}
                        </p>
                    </div>
                    <div
                        className={`${tableClasses["transaciton-detail-items"]} ${tableClasses["greyBg"]}`}>
                        <h3>wallet commission percentage</h3>
                        <p>:</p>

                        <p style={{ margin: "0" }}>
                            {transactionDetails?.walletCommissionPercentage}%
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategory;
