import React, { useEffect } from "react";
import tableClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
import { Services } from "Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function AddCategory(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.editable) {
            Services.getAdminDetailsById("GET", null, token, props.id)
                .then((res) => {
                    console.log(res);
                    if (res?.Status === 1) {
                        reset({
                            ...res.EmployeeDetails,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    const onSubmit = (data) => {
        console.log(data);

        let method = JSON.stringify({
            ...data,
        });
        if (!props.editable) {
            Services.addAdmin("POST", method, token)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories(0);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateAdmin("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories(0);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <div className={tableClasses.modal} onClick={CancelHandler}>
            <div
                className={tableClasses["modal-content"]}
                onClick={openhandler}>
                <div className={tableClasses["modal-content-header"]}>
                    <div>
                        <h3>{props.editable ? "Update User" : "Add User"}</h3>
                        <p>
                            {props.editable ? "Update User" : "Add User"} and
                            necessary information from here
                        </p>
                    </div>
                    <button
                        className={tableClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={tableClasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="firstName">First Name</label>
                        <div className={tableClasses["select"]}>
                            <input
                                {...register("firstName", {
                                    required: "first Name is required",
                                })}
                            />

                            {errors?.firstName && (
                                <p className={tableClasses["error"]}>
                                    {errors?.firstName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="lastName">Last Name</label>
                        <div className={tableClasses["select"]}>
                            <input
                                {...register("lastName", {
                                    required: "last Name is required",
                                })}
                            />

                            {errors?.lastName && (
                                <p className={tableClasses["error"]}>
                                    {errors?.lastName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <div className={tableClasses["select"]}>
                            <input
                                type={"number"}
                                {...register("mobileNumber", {
                                    required: "Mobile Number is required",
                                })}
                            />

                            {errors?.mobileNumber && (
                                <p className={tableClasses["error"]}>
                                    {errors?.mobileNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="emailId">Email</label>
                        <div className={tableClasses["select"]}>
                            <input
                                type={"email"}
                                autoComplete="off"
                                {...register("emailId", {
                                    required: "Email is required",
                                })}
                            />

                            {errors?.emailId && (
                                <p className={tableClasses["error"]}>
                                    {errors?.emailId?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="password">Password</label>
                        <div className={tableClasses["select"]}>
                            <input
                                autoComplete="new-password"
                                type={"password"}
                                {...register("password", {
                                    required: "password is required",
                                })}
                            />

                            {errors?.password && (
                                <p className={tableClasses["error"]}>
                                    {errors?.password?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="role">Role</label>
                        <div className={tableClasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("role", {
                                    required: "role Is required",
                                })}>
                                <option value="" disabled>
                                    Select role
                                </option>
                                {/* <option value="1">Super Admin</option> */}
                                <option value="2">Employee</option>
                            </select>
                            {errors?.role && (
                                <p className={tableClasses["error"]}>
                                    {errors?.role?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor=" status">Status</label>
                        <div className={tableClasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("status", {
                                    required: "status Is required",
                                })}>
                                <option value="" disabled>
                                    Select status
                                </option>
                                <option value={2}>Approval Pending</option>
                                <option value={1}>active</option>
                                <option value={0}>inactive</option>
                            </select>
                            {errors?.status && (
                                <p className={tableClasses["error"]}>
                                    {errors?.status?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={tableClasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={tableClasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add User"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
