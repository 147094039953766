import React, { useEffect, useState, useRef } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import { BiSearchAlt2 } from "react-icons/bi";
// import { BsDownload } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineModeEditOutline } from "react-icons/md";
import AddReferral from "Components/Referral/Addreferral";
import { useParams } from "react-router-dom";
function User() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const searchref = useRef();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const { userid } = useParams();
    const [addForm, setAddForm] = useState(false);
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [id, setid] = useState();
    const [editable, setEditable] = useState(false);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const formHandler = () => {
        setAddForm((prev) => !prev);
    };
    const edithandler = (e) => {
        console.log(e.currentTarget.getAttribute("id"));
        setid(e.currentTarget.getAttribute("id"));
        setEditable(true);
        setAddForm(true);
    };
    const getusers = (offset) => {
        Services.referralusers("GET", null, token, userid, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.Users);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.offset);
                    }
                    if (Response.offset !== -1) {
                        setoffset(Response.offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);

        // eslint-disable-next-line
    }, []);

    const deleteHandler = (e) => {
        if (window.confirm("are you sure you want to delete ")) {
            Services.DeleteReferral(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("id")
            )
                .then((res) => {
                    if (res.Status === 1) {
                        setTimeout(window.location.reload(), 2000);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // if (res.Message === "Token expired") {
                        //     Navigate("/");
                        // }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={tableClasses["action-btns"]}>
                <button id={cellcontent.ID} onClick={edithandler}>
                    <MdOutlineModeEditOutline size={22} />
                </button>
                <button id={cellcontent.ID} onClick={deleteHandler}>
                    <AiOutlineDelete size={22} />
                </button>
            </div>
        );
    };
    const columns = [
        {
            dataField: "userId",
            text: "User ID",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "firstName",
            text: "First Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "lastName",
            text: "Last Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "mobileNumber",
            text: "Mobile Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "walletAmount",
            text: "Wallet Amount",
            sort: false,
            formatter: (cell) => {
                return <p>₹ {cell}</p>;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const submithandler = (e) => {
        // setLoading(true);
        e.preventDefault();
        if (searchref.current.value.length == 0) {
            getusers(0);
            return;
        }
        let body = {};
        if (isNaN(searchref.current.value)) {
            body = { name: searchref.current.value, isKYCverified: 1 };
        } else {
            body = { mobileNumber: searchref.current.value, isKYCverified: 1 };
        }
        Services.SearchReferralUserBynameOrMobile(
            "POST",
            JSON.stringify(body),
            token
        )
            .then((Response) => {
                console.log(Response);
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.ReferralCodes);
                    setNext(true);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const clearHandler = (e) => {
        e.preventDefault();
        if (searchref.current.value.length > 0) {
            getusers(0);
            searchref.current.value = "";
        }
    };
    return (
        <>
            {/* {addForm && (
                <AddReferral
                    setmodal={setAddForm}
                    editable={editable}
                    id={id}
                    setEditable={setEditable}
                    getCategories={getusers}
                />
            )} */}
            <Layout isactive={"verified"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["table"]}>
                        <h3 className={tableClasses["heading"]}>
                            Referral Users
                        </h3>
                        {/* <button onClick={formHandler}>Add Referral</button> */}
                        {/* <form
                            className={tableClasses["search-form"]}
                            onSubmit={submithandler}>
                            <div>
                                <input
                                    type={"text"}
                                    ref={searchref}
                                    placeholder="Search by name or number"
                                />
                                <BiSearchAlt2
                                    className={tableClasses["search-icon"]}
                                    size={25}
                                    color={"#2e3346"}
                                />
                            </div>
                            <button type="submit">Search</button>
                            <button
                                onClick={clearHandler}
                                style={{
                                    marginLeft: "0.1rem",
                                    background: "red",
                                }}
                                type="button">
                                Clear
                            </button>
                        </form> */}
                        <Table
                            data={data}
                            columns={columns}
                            getdata={getusers}
                            getoffset={offset}
                            max={max}
                            setNext={setNext}
                            next={next}
                            prev={prev}
                            setprev={setprev}
                            prevoffset={prevoffset}
                            setPrevOffset={setPrevOffset}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
}

export default User;
