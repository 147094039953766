import React, { useEffect, useState } from "react";
import LoginClasses from "Styles/login.module.css";
import { useForm } from "react-hook-form";
import { AiOutlineUser } from "react-icons/ai";
import { RiLock2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Services } from "Services";
import { toast } from "react-toastify";
function Login() {
    const [submitEnabler, setSubmitEnable] = useState(true);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    //  form data watch
    const formdata = watch();
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        // load the script by passing the URL
        loadScriptByURL(
            "recaptcha-key",
            `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY_CAPTCHA}`,
            function () {
                console.log("Script loaded!");
            }
        );
        if (localStorage.getItem("token")) {
            Navigate("/dashboard/user", { replace: true });
        }
    }, []);
    useEffect(() => {
        if (formdata?.emailId?.length > 0 && formdata?.password?.length > 0) {
            setSubmitEnable(false);
        } else {
            setSubmitEnable(true);
        }
    }, [formdata]);
    const onSubmit = (data) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(process.env.REACT_APP_SITE_KEY_CAPTCHA, {
                    action: "submit",
                })
                .then((token) => {
                    Services.login(
                        "POST",
                        JSON.stringify({ ...data, token: token })
                    )
                        .then((response) => {
                            if (response.Status === 1) {
                                Navigate("/dashboard", { replace: true });

                                localStorage.setItem("token", response.Token);
                                localStorage.setItem(
                                    "userdetails",
                                    JSON.stringify(response.adminDetails)
                                );
                            } else {
                                toast.error(`${response.Message}`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                });
                            }
                            console.log(response);
                        })
                        .catch((err) => {
                            alert("something went wrong please try later");
                            console.log(err);
                        });
                });
        });
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["left-div"]}></div>
            <div className={LoginClasses["right-div"]}>
                <h3>
                    Welcome to <span>Easemybill</span>
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={LoginClasses["form-data"]}>
                        <label htmlFor="Username">Username</label>
                        <div className={LoginClasses["input"]}>
                            <AiOutlineUser className={LoginClasses["icon"]} />
                            <input
                                placeholder="Enter Username"
                                type={"text"}
                                {...register("emailId", {
                                    required: "Username is Required",
                                })}
                            />
                            {errors?.emailId && (
                                <p className={LoginClasses.error}>
                                    {errors?.emailId?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={LoginClasses["form-data"]}>
                        <label htmlFor="Password">Password</label>
                        <div className={LoginClasses["input"]}>
                            <RiLock2Line className={LoginClasses["icon"]} />
                            <input
                                placeholder="Enter Password"
                                type="password"
                                {...register("password", {
                                    required: "Password is Required",
                                })}
                            />
                            {errors?.password && (
                                <p className={LoginClasses.error}>
                                    {errors?.password?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <input
                        type={"submit"}
                        value={"Login"}
                        disabled={submitEnabler}
                    />
                </form>
            </div>
        </div>
    );
}

export default Login;
