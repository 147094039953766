import React from "react";
import tableClasses from "Styles/mainCss.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
import { Services } from "Services";
import { toast } from "react-toastify";
function AddCategory(props) {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };

    const onSubmit = (data) => {
        let method = JSON.stringify({
            ...data,
        });

        Services.addBlockedUser("POST", method, token, props.id)
            .then((res) => {
                if (res.Status === 1) {
                    props.setmodal(false);
                    props.getCategories(0);
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    return (
        <div className={tableClasses.modal} onClick={CancelHandler}>
            <div
                className={tableClasses["modal-content"]}
                onClick={openhandler}>
                <div className={tableClasses["modal-content-header"]}>
                    <div>
                        <h3>Add Account</h3>
                    </div>
                    <button
                        className={tableClasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={tableClasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={tableClasses["item-image-upload"]}>
                        <label htmlFor="accountNumber">Account number</label>
                        <div className={tableClasses["select"]}>
                            <input
                                type={"number"}
                                {...register("accountNumber", {
                                    required: "Account number is required",
                                })}
                            />

                            {errors?.accountNumber && (
                                <p className={tableClasses["error"]}>
                                    {errors?.accountNumber?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={tableClasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={tableClasses["add-category-btn"]}>
                            Block
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
