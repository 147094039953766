import React, { useState, useEffect } from "react";
import SidenavClasses from "Styles/sideNav.module.css";
import { NavLink } from "react-router-dom";
import logo from "Assets/logo.png";
import {
    MdDashboard,
    MdOutlineSpaceDashboard,
    MdOutlineNextPlan,
} from "react-icons/md";
import { IoTimer } from "react-icons/io5";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router";
import {
    BsFillWalletFill,
    BsListNested,
    BsFileEarmarkPerson,
} from "react-icons/bs";
import { VscReferences } from "react-icons/vsc";
import { AiFillSetting } from "react-icons/ai";
import { ImBlocked } from "react-icons/im";
import { RiAdminLine } from "react-icons/ri";
function SideNav({ isActive }) {
    const [admindata, setAdminData] = useState({});
    const Navigate = useNavigate();
    const logoutHandler = () => {
        if (window.confirm("Are you sure want to Logout?")) {
            localStorage.clear();
            Navigate("/");
        }
    };
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userdetails"));
        setAdminData(user);
    }, []);
    return (
        <div className={SidenavClasses["wrapper"]}>
            <img src={logo} alt="logo" />
            <div className={SidenavClasses["navlinks"]}>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "dashboard"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard">
                    <MdOutlineSpaceDashboard />
                    <p>Dashboard</p>
                </NavLink>
                {admindata?.role === 1 && (
                    <NavLink
                        className={`${SidenavClasses["link"]} ${
                            isActive === "admin"
                                ? SidenavClasses["ActiveLink"]
                                : ""
                        }`}
                        to="/dashboard/admin-users">
                        <RiAdminLine />
                        <p>Admin Users</p>
                    </NavLink>
                )}
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "payoutContacts"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard/payout-contacts-verify">
                    <BsFileEarmarkPerson />
                    <p>Payout Contacts</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "user" ? SidenavClasses["ActiveLink"] : ""
                    }`}
                    to="/dashboard/user">
                    <BsListNested />
                    <p>Users List</p>
                </NavLink>

                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "Kyc" ? SidenavClasses["ActiveLink"] : ""
                    }`}
                    to="/dashboard/kyc-pending-users">
                    <BsFileEarmarkPerson />
                    <p>KYC Pending</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "verified"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard/verified-users">
                    <MdDashboard />
                    <p>Verified User</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "Transaction"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard/transaction-history">
                    <IoTimer />
                    <p>Transaction</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "Wallet"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard/wallet-summary">
                    <BsFillWalletFill />
                    <p>Wallet Summary</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "REFERALCOMMISSION"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/referralCommission">
                    <VscReferences />
                    <p>Referral Commissions</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "REFERAL"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard/referral">
                    <VscReferences />
                    <p>Referral ID</p>
                </NavLink>
                <NavLink
                    className={`${SidenavClasses["link"]} ${
                        isActive === "Blocked"
                            ? SidenavClasses["ActiveLink"]
                            : ""
                    }`}
                    to="/dashboard/blockedAccounts">
                    <ImBlocked />
                    <p>Blocked Accounts</p>
                </NavLink>
                {admindata?.role === 1 && (
                    <NavLink
                        className={`${SidenavClasses["link"]} ${
                            isActive === "T1"
                                ? SidenavClasses["ActiveLink"]
                                : ""
                        }`}
                        to="/dashboard/T1-transactions">
                        <MdOutlineNextPlan />
                        <p>T1 Transactions</p>
                    </NavLink>
                )}
                {admindata?.role === 1 && (
                    <NavLink
                        className={`${SidenavClasses["link"]} ${
                            isActive === "settings"
                                ? SidenavClasses["ActiveLink"]
                                : ""
                        }`}
                        to="/dashboard/settings">
                        <AiFillSetting />
                        <p>Settings</p>
                    </NavLink>
                )}
                <button onClick={logoutHandler}>
                    <HiOutlineLogout size={25} />
                    <p> Logout</p>
                </button>
            </div>
        </div>
    );
}

export default SideNav;
