import React, { useEffect, useState, useRef } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
function User() {
    const { id } = useParams();
    console.log(id);
    const [loading, setLoading] = useState(true);
    const fromref = useRef();
    const toref = useRef();
    const searchref = useRef();
    const [data, setData] = useState([]);
    let bodydata = {};
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const getusers = (offset) => {
        let body = JSON.stringify({
            ...bodydata,
        });
        Services.userTransactions("GET", null, token, id, offset)
            .then((Response) => {
                setLoading(false);
                console.log(Response);
                // offset
                if (Response.Status === 1) {
                    setData(Response.WalletTransactions);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.Offset);
                    }

                    if (Response.Offset !== -1) {
                        setoffset(Response.Offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);
        // eslint-disable-next-line
    }, []);
    const StatusFormatter = (cell) => {
        if (cell === 1) {
            return <p style={{ margin: "0" }}>Processed</p>;
        }
        if (cell === 2) {
            return <p style={{ margin: "0" }}>Pending</p>;
        }
        if (cell === 3) {
            return <p style={{ margin: "0" }}>Payout failed</p>;
        }
        if (cell === 4) {
            return <p style={{ margin: "0" }}>Payout Reversed</p>;
        }
    };
    const dateFormatter = (cell) => {
        const date = new Date(cell);
        return (
            <Moment
                format="MMMM Do YYYY hh:mm a"
                subtract={{ hours: 5, minutes: 30 }}>
                {date}
            </Moment>
        );
    };
    const debitFormatter = (cell, cellcontent) => {
        if (cell === 2 || cell === 3 || cell === 6 || cell === 7) {
            return (
                <p style={{ color: "red", margin: "0" }}>
                    ₹{cellcontent.amount}
                </p>
            );
        }
    };
    const columns = [
        {
            dataField: "wallet_transactionId",
            text: "Order ID",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionDateTime",
            text: "Date & Time",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "description",
            text: "Description",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionType",
            text: "Credit",
            sort: false,
            formatter: (cell, cellcontent) => {
                if (cell === 1 || cell === 4 || cell === 5 || cell === 8) {
                    if (cellcontent.paymentGateway == "razorpay") {
                        return (
                            <p style={{ color: "green", margin: "0" }}>
                                ₹{cellcontent.amount}
                            </p>
                        );
                    } else {
                    }
                    return (
                        <p style={{ color: "blue", margin: "0" }}>
                            ₹{cellcontent.amount}
                        </p>
                    );
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "10%" };
            },
        },
        {
            dataField: "transactionType",
            text: "Debit",
            sort: false,
            formatter: debitFormatter,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "10%" };
            },
        },
        {
            dataField: "after_balance",
            text: "Closing Balance",
            sort: false,
            formatter: (cell) => (
                <p style={{ textAlign: "center" }}>₹{cell} </p>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionStatus",
            text: "Status",
            sort: false,
            formatter: StatusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const getTransactions = () => {
        getusers(0);
    };
    const transactionsHandler = (e) => {
        const current = new Date(fromref.current.value);
        const to = new Date(toref.current.value);
        /* 
        
        */
        bodydata = {
            fromDate: `${current.getFullYear()}-${String(
                current.getMonth() + 1
            ).padStart(2, "0")}-${String(current.getDate()).padStart(2, "0")}`,
            toDate: `${to.getFullYear()}-${String(to.getMonth() + 1).padStart(
                2,
                "0"
            )}-${String(to.getDate()).padStart(2, "0")}`,
        };
        getTransactions();
    };
    const submithandler = (e) => {
        // setLoading(true);
        e.preventDefault();
        if (searchref.current.value.length == 0) {
            getusers(0);
            return;
        }

        Services.TransactionsById("GET", null, token, searchref.current.value)
            .then((Response) => {
                console.log(Response);
                setLoading(false);
                if (Response.Status === 1) {
                    setData([Response.walletTransaction]);
                    setNext(true);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const clearHandler = (e) => {
        e.preventDefault();
        if (searchref.current.value.length > 0) {
            getusers(0);
            searchref.current.value = "";
        }
    };
    const rowClasses = (row) =>
        row.paymentGateway == "razorpay" ? "red" : "blue";
    return (
        <Layout isactive={"Wallet"}>
            {loading ? (
                <Loader />
            ) : (
                <div className={tableClasses["table"]}>
                    <h3 className={tableClasses["heading"]}>Transactions</h3>
                    <div className={tableClasses["search-download"]}>
                        <form
                            className={tableClasses["search-form"]}
                            onSubmit={submithandler}>
                            <div>
                                <input
                                    type={"text"}
                                    ref={searchref}
                                    placeholder="Search by Transaction Id"
                                />
                                <BiSearchAlt2
                                    className={tableClasses["search-icon"]}
                                    size={25}
                                    color={"#2e3346"}
                                />
                            </div>
                            <button type="submit">Search</button>
                            <button
                                onClick={clearHandler}
                                style={{
                                    marginLeft: "0.1rem",
                                    background: "red",
                                }}
                                type="button">
                                Clear
                            </button>
                        </form>
                        {/* <button className={tableClasses["download"]}>
                            <BsDownload />
                            Download
                        </button> */}
                    </div>

                    {/* <div className={tableClasses["dateinputs"]}>
                        <input type={"date"} ref={fromref} />
                        <input
                            type={"date"}
                            ref={toref}
                            max={new Date().toISOString().split("T")[0]}
                        />
                        <button onClick={transactionsHandler}>Go</button>
                    </div> */}
                    <Table
                        rowClasses={rowClasses}
                        data={data}
                        columns={columns}
                        getdata={getusers}
                        getoffset={offset}
                        max={max}
                        setNext={setNext}
                        next={next}
                        prev={prev}
                        setprev={setprev}
                        prevoffset={prevoffset}
                        setPrevOffset={setPrevOffset}
                    />
                </div>
            )}
        </Layout>
    );
}

export default User;
