import React from "react";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import mainClasses from "Styles/mainCss.module.css";
function Loader() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    return (
        <div className={mainClasses["loader"]}>
            <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
            />
        </div>
    );
}

export default Loader;
